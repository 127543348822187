import { Badge, styled } from "@mui/material";
import { Style } from "@mui/icons-material";
import React from "react";
import { Props } from "models/props";

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            border: '1px solid currentColor',
            content: '""',
        },
    },
    '@keyframes ripple': {
        '0%': {
            transform: 'scale(.8)',
            opacity: 1,
        },
        '100%': {
            transform: 'scale(2.4)',
            opacity: 0,
        },
    },
}));

export type ActivityBadgeProps = Props & {
    available: boolean;
}

const ActivityBadge: React.FC<ActivityBadgeProps> = ({available, children}) => (
    <StyledBadge
        overlap={"circular"}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        variant="dot"
        sx={{
            "& .MuiBadge-badge": {
                backgroundColor: available ? "green" : "grey",
                color: available ? "green" : "grey",
                '&::after': {
                    animation: available ? 'ripple 1.2s infinite ease-in-out' : "none"
                }
            }
        }}
    >
        {children}
    </StyledBadge>
)

export { ActivityBadge }