import React, { useCallback, useState } from "react";
import {
    Button,
    Card,
    Checkbox,
    Chip,
    CircularProgress,
    Collapse,
    Fade,
    Grid,
    Slide,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
    useTheme,
} from "@mui/material";
import { EnquiryRequest } from "models/enquiry-request";
import { nameof } from "helpers/typeHelper";
import { Formik } from "formik";
import { SendRounded } from "@mui/icons-material";
import { FileDropZone } from "components/input/file-drop-zone";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { contactFormValidation } from "models/validation/contact-form-validation";
import { GroupSummary } from "components/layout/group-summary";
import { GlassCard } from "components/layout/glass-card";
import { Group } from "models/freshworks/freshdesk/group";
import { Member } from "models/schemas/member";
import { Asset } from "models/schemas/asset";

export type ContactFormProps = {
    groups: Group[],
    members: Member[],
    placerholder: Asset,
    onSubmit: (values: EnquiryRequest, token: string) => Promise<void>;
    disableSubmit: boolean;
    
    initialState: EnquiryRequest;
};

const ContactForm: React.FC<ContactFormProps> = ({
                                                     groups,
                                                     members,
                                                     placerholder,
                                                     onSubmit,
                                                     disableSubmit,
                                                     initialState
                                                 }) => {

    const [requiresNDA, setRequiresNDA] = useState<boolean>(false);
    const [submitting, setSubmitting] = useState<boolean>(false)
    
    const theme = useTheme();
    const { executeRecaptcha } = useGoogleReCaptcha();

    const submitForm = useCallback(async (enquiry: EnquiryRequest) => {

        if (!executeRecaptcha) {
            console.log("Recaptcha needs to load");
            return;
        }

        setSubmitting(true)
        onSubmit(enquiry, await executeRecaptcha()).finally(() => setSubmitting(false))
    }, [executeRecaptcha])

    return (
        <Formik
            initialValues={initialState}
            validationSchema={contactFormValidation}
            onSubmit={submitForm}
            enableReinitialize
        >
            {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  setFieldValue,
                  handleSubmit,
              }) => (
                <Grid container spacing={2} alignItems={"center"}>
                    <Grid item container md={6} justifyContent={"center"} spacing={1}>
                        <Grid item>
                            {groups.length > 0 ?
                                <Slide in={groups.length > 0} direction={"up"}>
                                    <div>
                                        <Fade in={groups.length > 0}>
                                            <ToggleButtonGroup
                                                color={"primary"}
                                                size={"small"}
                                                fullWidth
                                                onChange={(_, v) => setFieldValue(nameof<EnquiryRequest>("groupId"), v)}
                                                value={values.groupId}
                                                exclusive
                                                orientation={"vertical"}
                                            >
                                                {groups.map(g => (
                                                    <ToggleButton value={g.id} key={g.name}>
                                                        <GroupSummary group={g} members={members}
                                                                      placeholder={placerholder}/>
                                                    </ToggleButton>
                                                ))}
                                            </ToggleButtonGroup>
                                        </Fade>
                                    </div>
                                </Slide> :
                                <CircularProgress/>
                            }
                        </Grid>
                        <Grid item xs={12}>
                            <Collapse in={touched.groupId && Boolean(errors.groupId)}>
                                <Typography color={"error"} fontSize={12}
                                            align={"center"}>{errors.groupId}</Typography>
                            </Collapse>
                        </Grid>
                    </Grid>
                    <Grid item md={6}>
                        <GlassCard>
                            <Grid item container sx={{padding: theme.spacing(2)}}>
                                <Grid container spacing={1} justifyContent={"center"}>
                                    <Grid item xs={6}>
                                        <Card sx={{ p: 1 }}>
                                            <TextField
                                                name={nameof<EnquiryRequest>("firstName")}
                                                placeholder={"First Name*"}
                                                value={values.firstName}
                                                onChange={handleChange}
                                                helperText={touched.firstName && errors.firstName}
                                                error={touched.firstName && Boolean(errors.firstName)}
                                            />
                                        </Card>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Card sx={{ p: 1 }}>
                                            <TextField
                                                name={nameof<EnquiryRequest>("surname")}
                                                placeholder={"Surname*"}
                                                value={values.surname}
                                                onChange={handleChange}
                                                helperText={touched.surname && errors.surname}
                                                error={touched.surname && Boolean(errors.surname)}
                                            />
                                        </Card>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Card sx={{ p: 1 }}>
                                            <TextField
                                                name={nameof<EnquiryRequest>("email")}
                                                placeholder={"Email*"}
                                                value={values.email}
                                                onChange={handleChange}
                                                helperText={touched.email && errors.email}
                                                error={touched.email && Boolean(errors.email)}
                                            />
                                        </Card>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Card sx={{ p: 1 }}>
                                            <TextField
                                                name={nameof<EnquiryRequest>("subject")}
                                                placeholder={"Subject*"}
                                                value={values.subject}
                                                onChange={handleChange}
                                                helperText={touched.subject && errors.subject}
                                                error={touched.subject && Boolean(errors.subject)}
                                            />
                                        </Card>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Card sx={{ p: 1 }}>
                                            <TextField
                                                name={nameof<EnquiryRequest>("message")}
                                                placeholder={"Message*"}
                                                value={values.message}
                                                onChange={handleChange}
                                                multiline
                                                rows={5}
                                                helperText={touched.message && errors.message}
                                                error={touched.message && Boolean(errors.message)}
                                            />
                                        </Card>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Collapse in={requiresNDA && values.files.length === 0}>
                                            <FileDropZone
                                                onFilesDropped={(f) => {
                                                    setFieldValue(nameof<EnquiryRequest>("files"), f)
                                                }}
                                                filetypes={["application/pdf"]}
                                            />
                                        </Collapse>
                                    </Grid>
                                    <Grid item container spacing={1} justifyContent={"center"}>
                                        {values.files !== undefined &&
                                            values.files.map(file => (
                                                <Grid item key={file.name}>
                                                    <Chip label={file.name}
                                                          onDelete={() => setFieldValue(nameof<EnquiryRequest>("files"), values.files.filter(f => f.name !== file.name))}/>
                                                </Grid>
                                            ))
                                        }
                                    </Grid>
                                    <Grid
                                        item
                                        container
                                        justifyContent={"flex-end"}
                                        alignContent={"stretch"}
                                        spacing={2}
                                    >
                                        <Grid item container xs alignItems={"center"}>
                                            <Grid item xs>
                                                <Typography variant={"caption"}>
                                                    Do you require us to sign an NDA?
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Checkbox
                                                    value={requiresNDA}
                                                    onChange={(e, c) => setRequiresNDA(c)}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                variant={"outlined"}
                                                color={"success"}
                                                startIcon={submitting? <CircularProgress color={"success"} size={25}/> : <SendRounded/>}
                                                onClick={() => handleSubmit()}
                                                disabled={submitting || disableSubmit || !executeRecaptcha}
                                            >
                                                Send
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </GlassCard>
                    </Grid>
                </Grid>
            )}
        </Formik>
    );
};
export { ContactForm };
