import { EnquiryRequest } from "models/enquiryRequest";
import SnackbarUtils from "components/notifications/snackbar";
import { nameof } from "helpers/typeHelper";
import { Group } from "models/freshworks/freshdesk/group";

class FreshworksApi {
    baseUrl: string = process.env.GATSBY_FRESHWORKS_URL ?? "";
    
    getGroups = (): Promise<Group[]> => {
        return fetch(`${this.baseUrl}/api/get-groups`).then(resp => {
            if (!resp.ok) {
                SnackbarUtils.error(`Error: ${resp.statusText}`)
                return [];
            }

            return resp.json()
        }, reason => {
            SnackbarUtils.error(`${reason}`)
            return [];
        });
    }
    
    submitEnquiry = (enquiry: EnquiryRequest, token: string): Promise<void> => {
        
        const data: any = new FormData();
        data.append(nameof<EnquiryRequest>("firstName"), enquiry.firstName)
        data.append(nameof<EnquiryRequest>("surname"), enquiry.surname)
        data.append(nameof<EnquiryRequest>("email"), enquiry.email)
        data.append(nameof<EnquiryRequest>("message"), enquiry.message)
        data.append(nameof<EnquiryRequest>("subject"), enquiry.subject)
        data.append(nameof<EnquiryRequest>("groupId"), enquiry.groupId)
        
        enquiry.files.map(f => data.append(nameof<EnquiryRequest>("files"), f))
        
        
        // Post the payload to the contact endpoint.
        return fetch(`${this.baseUrl}/api/create-ticket`, {
            method: 'post',
            headers: {
                "Content-Type": "multipart/form-data;",
                "Recaptcha": token
            },
            body: data,
        }).then(resp => {
            if (!resp.ok) {
                SnackbarUtils.error(`Error: ${resp.statusText}`)
                return;
            }

            SnackbarUtils.success(`Thanks for getting in touch ${enquiry.firstName}, you'll hear back from us soon!`)
            
            return Promise.resolve()
        }, reason => {
            SnackbarUtils.error(`Error: ${reason}`)
        });
    }
}
const freshworksApi = new FreshworksApi();
export { freshworksApi }