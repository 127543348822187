import * as Yup from "yup";

export const contactFormValidation = Yup.object().shape({
    firstName: Yup.string().required("Required"),
    surname: Yup.string().required("Required"),
    email: Yup.string().required("Required").email("Must be a valid email address"),
    subject: Yup.string().required("Required"),
    message: Yup.string().required("Please specify why you would like to contact us"),
    groupId: Yup.number().required("Please pick a team above").typeError("Please pick a team above")
});
