import React, { useCallback, useRef, useState } from "react";

import { useTheme } from "@emotion/react";
import { FileUploadOutlined } from "@mui/icons-material";
import { Grid, Link, SxProps, Typography } from "@mui/material";

import "./file-drop-zone.scss";
import { readableFilesize } from "helpers/stringHelper";
import SnackbarUtils from "components/notifications/snackbar";

export type FileDropZoneProps = {
    onFilesDropped: (files: File[]) => void;
    uploadDescription?: string;
    maxFilesize?: number;
    disabled?: boolean;

    filetypes: string[]
}

const FileDropZone: React.FC<FileDropZoneProps> = ({
                                                       onFilesDropped,
                                                       uploadDescription,
                                                       maxFilesize,
                                                       filetypes,
                                                       disabled = false
                                                   }) => {

    const theme = useTheme();

    const inputFile: any = useRef(undefined);

    const [uploading, setUploading] = useState<boolean>(false);
    const [dragOver, setDragOver] = useState<number>(0);

    const validateFiles = useCallback((fileList: FileList): File[] => {
        let files: File[] = Array.from(fileList)

        if (maxFilesize === undefined)
            return files;

        let validatedFiles: File[] = [];

        files.forEach(file => {
            if (file.size > maxFilesize) {
                SnackbarUtils.error(`${file.name} exceeds to max filesize of ${readableFilesize(maxFilesize)}!`);
                return;
            }

            if (!filetypes.includes(file.type)) {
                SnackbarUtils.error(`${file.type} is not a supported file type!`);
            }

            validatedFiles.push(file);
        })

        return validatedFiles;
    }, [maxFilesize, filetypes])

    const uploadFiles = useCallback((files: File[]) => {

        if (files.length === 0)
            return;

        onFilesDropped(files);
        setDragOver(0)

    }, [onFilesDropped])

    const onDragChange = useCallback((e: React.DragEvent<HTMLDivElement>, enter: boolean) => {
        e.preventDefault();
        e.stopPropagation();

        setDragOver(prevState => enter ? prevState + 1 : prevState - 1);
    }, [setDragOver])

    const onDrop = useCallback((event: React.DragEvent<HTMLDivElement>) => {

        event.preventDefault();
        event.stopPropagation();

        setDragOver(0)

        if (disabled)
            return;

        uploadFiles(validateFiles(event.dataTransfer.files));
    }, [uploadFiles, validateFiles, disabled])

    const onChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const files: FileList | null = event.target.files;

        if (files) {
            uploadFiles(validateFiles(files))
        }
    }, [uploadFiles, validateFiles])

    const onDragOver = useCallback((event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault()
        event.stopPropagation()
    }, [])

    const sxProps: SxProps = {
        border: `2px dashed ${dragOver ? theme.palette.primary.main : theme.palette.text.secondary}`
    };

    return (
        <Grid container item className={`dropzone`}
              sx={sxProps}
              onDrop={e => onDrop(e)}
              onDragOver={e => onDragOver(e)}
              onDragEnter={e => onDragChange(e, true)}
              onDragLeave={e => onDragChange(e, false)}>
            <Grid container item spacing={1} justifyContent={"center"} alignContent={"center"}>
                <Grid item className={"dropzone-content"}>
                    <FileUploadOutlined fontSize={"small"}/>
                </Grid>
                <Grid item className={"dropzone-content"}>
                    {uploading
                        ? <Typography variant={"caption"}>Uploading...</Typography>
                        : <Typography variant={"caption"}>Drop or <Link color={"primary"}
                                                                        onClick={() => inputFile.current.click()}>Upload</Link> {uploadDescription && uploadDescription}
                        </Typography>
                    }

                </Grid>
            </Grid>
            <input type={"file"} onChange={onChange} ref={inputFile} disabled={disabled} hidden/>
        </Grid>
    )

}
export { FileDropZone }