import React, { useCallback } from "react";
import { Avatar, AvatarGroup, Divider, Grid, ToggleButton, Typography } from "@mui/material";
import { ActivityBadge } from "components/notifications/activity-badge";
import { Group } from "models/freshworks/freshdesk/group";
import { Member } from "models/schemas/member";
import { Asset } from "models/schemas/asset";

export type GroupToggleButtonProps = {
    group: Group,
    members: Member[],
    placeholder: Asset
}

const GroupSummary: React.FC<GroupToggleButtonProps> = ({ group, members, placeholder }) => {

    const agents = group.agents.filter(a => members.some(m => m.freshdeskAgentId === `${a.id}`))
    
    const getMemberFromAgentId = useCallback((id: number): Member => {
        return members.find(m => m.freshdeskAgentId === id.toString())!
    }, [members])

    return (
        <Grid container spacing={2} alignItems={"center"}>
            <Grid item container xs>
                <Grid item xs={12}>
                    <Typography fontWeight={"bold"} align={"left"}
                                color={"textPrimary"}>{group.name}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant={"body2"} align={"left"}
                                color={"textSecondary"}>{group.description}</Typography>
                </Grid>
            </Grid>
            <Grid item>
                <Divider orientation={"horizontal"}/>
            </Grid>
            <Grid item>
                <AvatarGroup color={"rgba(255,255,255,1)"}>
                    {agents.length > 0 ?
                        agents.map(agent => {
                            const member = getMemberFromAgentId(agent.id)
                            
                            return (
                                <ActivityBadge available={agent.available} key={agent.id}>
                                    <Avatar>
                                        <img src={member.image.url} width={"100%"}/>
                                    </Avatar>
                                </ActivityBadge>
                            )
                        }) :
                        <Avatar>
                            <img src={placeholder.url} alt={placeholder.alt} width={"100%"}/>
                        </Avatar>
                    }
                </AvatarGroup>
            </Grid>
        </Grid>
    )
}
export { GroupSummary }