export type EnquiryRequest = {
    firstName: string,
    surname: string,
    email: string,
    subject: string,
    message: string,
    files: File[],
    groupId: number | undefined
}

export const enquiryRequestInitialState: EnquiryRequest = {
    firstName: "",
    surname: "",
    email: "",
    subject: "",
    message: "",
    files: [],
    groupId: undefined,
}