import React, { useCallback, useEffect, useState } from "react";

import { Box, Container, Grid, Typography, } from "@mui/material";

import { Layout } from "layouts/layout";
import { ContactForm } from "components/forms/contact-form";

import * as layout from "themes/layout.module.scss";
import { EnquiryRequest, enquiryRequestInitialState } from "models/enquiry-request";
import { SectionHeader } from "components/layout/section-header";
import { freshworksApi } from "apis/freshworks/freshworksApi";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { graphql, HeadFC, HeadProps, PageProps } from "gatsby";
import { Asset } from "models/schemas/asset";
import { Group } from "models/freshworks/freshdesk/group";
import { Member } from "models/schemas/member";
import { PageMetadata } from "components/layout/page-metadata";
import { useQueryParamString } from "react-use-query-param-string";
import { Page } from "models/schemas/page";
import { GraphQlArray } from "models/graphql/graphql-array";

export type GetInTouchProps = {
    members: GraphQlArray<Member>,
    asset: Asset,
    page: Page
}

const GetInTouch: React.FC<PageProps<GetInTouchProps>> = ({ data }) => {

    const { members, asset, page } = data;
    
    const [groups, setGroups] = useState<Group[]>([])
    const [allowSubmission, setAllowSubmission] = useState<boolean>(true);
    const [email, setEmail, initialized] = useQueryParamString('email', '');

    useEffect(() => {
       freshworksApi.getGroups().then((g: Group[]) => setGroups(g))
    }, [])

    const submitForm = useCallback((enquiry: EnquiryRequest, token: string): Promise<void> => {
        return freshworksApi.submitEnquiry(enquiry, token).then(() => {
            setAllowSubmission(false)
        });
    }, [])
    
    return (
        <Layout>
            <GoogleReCaptchaProvider reCaptchaKey={`${process.env.GATSBY_RECAPTCHA_SITE_KEY}`}>
                <Container maxWidth={"lg"}>
                    <Grid container justifyContent={"center"} alignItems={"center"} spacing={5} position={"relative"}
                          pt={"2.0em"}
                          pb={"2.0em"}>
                        <Box className={"radial-gradient"} width={"80.0vw"}></Box>
                        <Grid item container>
                            <SectionHeader
                                title={page.link.label}
                                description={
                                    <Typography color={"textSecondary"} variant={"body2"} align={"center"}>Have a question or need a quotation? Or maybe you just want to say <span
                                        style={{ color: layout.primary }}>"Howdy!"</span>
                                        <br/>All can be done by filling in the form below.
                                    </Typography>
                                }
                            />
                        </Grid>
                        <Grid item container>
                            <ContactForm
                                groups={groups}
                                members={members.nodes}
                                placerholder={asset}
                                onSubmit={submitForm}
                                disableSubmit={!allowSubmission}
                                initialState={{
                                    ...enquiryRequestInitialState,
                                    email: email ?? ""
                                }}
                            />
                        </Grid>
                    </Grid>
                </Container>
            </GoogleReCaptchaProvider>
        </Layout>
    )
}
export default GetInTouch;

export const Head: HeadFC = (props: HeadProps<any>) => (
    <PageMetadata location={props.location.pathname} metadata={props.data.page.metadata}/>
)

export const query = graphql`
{
    members: allGraphCmsMember {
        nodes {
            firstName
            surname
            freshdeskAgentId
            image {
                  alt
                  url
            }
        }
    }
    asset: graphCmsAsset(identifier: { eq: "logo-light" }) {
         url
         alt
    }
    page: graphCmsPage(identifier: { eq: "get-in-touch" }) {
        link {
            label
            url
        }
        metadata {
            title
            description
            thumbnail {
              url
            }
            embedImage {
              url
            }
            jsonLD
        }
    }
}
`