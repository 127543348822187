import _ from "lodash";

export function readableFilesize(bytes: number, si=false, dp=1) {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
        return bytes + ' B';
    }

    const units = si
        ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
        : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    const r = 10**dp;

    do {
        bytes /= thresh;
        ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


    return bytes.toFixed(dp) + ' ' + units[u];
}

export function isEmptyOrUndefined(text: string) {
    return text === "" || text === undefined || text === null || text === "null";
}

export function convertToSentence(text: string): string {
    return _.startCase(text)
}

export function convertToSlug(text: string): string {
    return text
        .toLowerCase()
        .replace(/[^a-z0-9]+/g, '-')
        .replace(/^-+|-+$/g, '')
}

export function getInitials(names: string[]): string {
    let initials: string = "";
    for(let i = 0; i < names.length; i++){
        if(!isEmptyOrSpaces(names[i]))
            initials += names[i][0].toUpperCase();
    }

    return initials;
}

export function isEmptyOrSpaces(str: string | any): boolean{
    return str?.toString() === null || str?.toString().match(/^ *$/) !== null;
}